<template>
  <div class="v-container">
    <div class="v-row v-mb-md">
      <div
        class="v-col-12 v-mb-sm v-account-management--title v-title"
        v-html="translate('accountManagementPage.titleLogin')"
      />
    </div>
    <div class="v-row v-mb-md">
      <div class="v-col-12">
        <common-phone-input
          focus-on-mount
          :label="translate('accountManagementPage.enterPhoneNumber')"
          :validator-form="validatorForm"
          v-model:phone="inputPhone"
        />
      </div>
      <div class="v-col-12">
        <v-input
          class-name="v-arora-input"
          required
          v-form-validator="{
            Form: validatorForm,
            Value: loginFields.password,
            Required: true,
            Validations: ['lengthCode'],
            MaxLength: 255
          }"
          :label="translate('accountManagementPage.enterPassword')"
          enterkeyhint="go"
          type="password"
          v-model:text="loginFields.password"
          @keyup.enter="login()"
        />
      </div>
      <div class="v-col-12">
        <arora-button
          class-name="v-btn-login-link v-btn v-btn-link"
          ignore-settings
          :label="translate('accountManagementPage.forgottenPass')"
          @click="setLKModeForgot"
        />
      </div>
    </div>
    <div class="v-row v-mb-md">
      <div class="v-col-12">
        <lazy-common-captcha />
      </div>
    </div>
    <div class="v-row">
      <div class="v-col-12 v-col-md-6 v-mb-sm">
        <div v-html="translate('accountManagementPage.registerSuggestion')" />

        <arora-button
          class-name="v-btn-login-link v-btn v-btn-link"
          ignore-settings
          :label="translate('accountManagementPage.register')"
          @click="setLKModeRegister"
        />
      </div>
      <div class="v-col-12 v-col-md-6 v-mb-sm">
        <arora-button
          class-name="v-w-100"
          :disabled="loginDisabled || clicked"
          :label="translate('accountManagementPage.login')"
          @click="async () => await login()"
        />
      </div>
      <transition
        appear
        mode="out-in"
        name="fade"
      >
        <div
          v-if="loginError"
          class="v-col-12 v-text-center v-error-color"
          v-html="loginError"
        />
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { LKMode } from '~api/consts'

import type { LoginPayload } from '~types/accountStore'
import type { AccountManagementCommon } from '~types/props'

import { type ServerError, useValidationStore, vFormValidator, VInput } from '@arora/common'
import { getActivePinia } from 'pinia'

const { phone } = defineProps<AccountManagementCommon>()

const emit = defineEmits(['update:mode', 'update:phone'])

const restaurantStore = useRestaurantStore()
const accountStore = useAccountStore()
const validationStore = useValidationStore(getActivePinia())

const appConfig = useAppConfig()
const { handleAuthError } = useAccount()
const { translate } = useI18nSanitized()
const { phoneLength } = usePhoneNumber()
const { reload } = useUrl()
const { eventEmit } = useEmitter()

const loginError = ref<string | null>(null)

const loginFields = ref<LoginPayload>({
  phone: null as string | null,
  password: null as string | null,
  recaptcha: null as string | null,
  smartcaptcha: null as string | null,
  country: '',
  passedV3: true
})

function setLKModeForgot(): void {
  emit('update:mode', LKMode.RequestNewPassFirstStage)
}

function setLKModeRegister(): void {
  emit('update:mode', LKMode.RegisterFirstStage)
}

const inputPhone = computed<string>({
  get() {
    return phone
  },
  set(value) {
    emit('update:phone', value ?? '')
  }
})

const loginDisabled = computed<boolean>(() => {
  return (
    inputPhone.value?.length !== phoneLength.value ||
    (loginFields.value.password?.trim()?.length ?? 0) === 0
  )
})
const clicked = ref<boolean>(false)

async function handleErrorMessage(error: ServerError): Promise<void> {
  loginError.value = await handleAuthError(error)
}

const validatorForm = 'login'

async function login(): Promise<void> {
  if (loginDisabled.value) return //for enter keypress

  if (!validationStore.formPassedCheck(validatorForm)) {
    return
  }
  clicked.value = true

  loginFields.value = await restaurantStore.validateCaptcha(loginFields.value)

  loginFields.value.country = appConfig.VueSettingsPreRun.SelectedLanguage

  loginFields.value.phone = inputPhone.value ?? ''
  const error = await accountStore.login(loginFields.value)

  await handleErrorMessage(error)
  clicked.value = false
  if (error.code === 0) reload()
  else eventEmit('v-reset-captcha')
}
</script>

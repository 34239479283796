<template>
  <svg
    class="v-icon-telegram"
    fill="none"
    height="28"
    viewBox="0 0 28 28"
    width="28"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.89545 12.3175C10.5082 8.41277 21.6124 3.65877 23.1898 2.99413C27.2228 1.27715 28.4685 1.60024 27.8501 5.4219C27.402 8.19122 26.1204 17.2469 25.1077 22.9056C24.4982 26.2564 23.1449 26.6534 21.003 25.2041C19.9813 24.5025 14.8011 20.9855 13.645 20.1455C12.4888 19.3054 11.2072 18.4839 12.9818 16.693C13.6091 16.0561 17.7497 11.9852 20.985 8.8097C21.0623 8.72162 21.1064 8.60805 21.1096 8.48942C21.1128 8.37079 21.0748 8.25488 21.0024 8.16254C20.93 8.0702 20.8281 8.00748 20.7148 7.98566C20.6015 7.96384 20.4844 7.98436 20.3846 8.04353L9.26242 15.6776C8.65564 16.1386 7.94574 16.4344 7.19847 16.5376C6.4512 16.6409 5.69072 16.5483 4.98743 16.2684C3.48177 15.8253 2.003 15.2899 1.40253 15.0868C-0.78426 14.3022 -0.255487 13.296 1.89545 12.3175Z"
      fill="white"
    />
  </svg>
</template>

<template>
  <svg
    class="v-icon-message"
    fill="none"
    height="27"
    viewBox="0 0 30 27"
    width="30"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 27V4.36576C2.5 3.69136 2.72293 3.12871 3.16878 2.67781C3.61463 2.22691 4.1693 2.00098 4.83278 2H26.1672C26.8317 2 27.3863 2.22594 27.8312 2.67781C28.2761 3.12969 28.499 3.69234 28.5 4.36576V20.1311C28.5 20.8045 28.2771 21.3672 27.8312 21.8191C27.3854 22.2709 26.8307 22.4964 26.1672 22.4954H6.94456L2.5 27ZM9.72222 13.3735C10.0207 13.3735 10.2807 13.2612 10.5022 13.0368C10.7237 12.8123 10.8344 12.5493 10.8344 12.2477C10.8344 11.9461 10.7237 11.6826 10.5022 11.4572C10.2807 11.2317 10.0207 11.1195 9.72222 11.1205C9.4237 11.1214 9.1637 11.2337 8.94222 11.4572C8.72074 11.6807 8.61 11.9442 8.61 12.2477C8.61 12.5512 8.72074 12.8147 8.94222 13.0382C9.1637 13.2617 9.4237 13.374 9.72222 13.3749M15.5 13.3749C15.7985 13.3749 16.0585 13.2627 16.28 13.0382C16.5015 12.8138 16.6122 12.5503 16.6122 12.2477C16.6122 11.9452 16.5015 11.6816 16.28 11.4572C16.0585 11.2327 15.7985 11.1205 15.5 11.1205C15.2015 11.1205 14.9415 11.2327 14.72 11.4572C14.4985 11.6816 14.3878 11.9452 14.3878 12.2477C14.3878 12.5503 14.4985 12.8138 14.72 13.0382C14.9415 13.2627 15.2015 13.3749 15.5 13.3749ZM21.2778 13.3749C21.5763 13.3749 21.8363 13.2627 22.0578 13.0382C22.2793 12.8138 22.39 12.5503 22.39 12.2477C22.39 11.9452 22.2793 11.6816 22.0578 11.4572C21.8363 11.2327 21.5763 11.1205 21.2778 11.1205C20.9793 11.1205 20.7193 11.2327 20.4978 11.4572C20.2763 11.6816 20.1656 11.9452 20.1656 12.2477C20.1656 12.5503 20.2763 12.8138 20.4978 13.0382C20.7193 13.2627 20.9793 13.3749 21.2778 13.3749Z"
      fill="#1A1A1A"
    />
  </svg>
</template>

<template>
  <svg
    v-if="appConfig.VueSettingsPreRun.IconsLayout === 'Lhun'"
    class="v-icon-phone"
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.273 2.96085L17.2105 2.02337C16.7691 1.92181 16.316 2.15227 16.1363 2.56633L14.2614 6.94124C14.0973 7.32404 14.2067 7.77325 14.5309 8.03496L16.8981 9.97242C15.4918 12.9685 13.0348 15.4606 9.97632 16.8942L8.03886 14.527C7.77324 14.2028 7.32793 14.0934 6.94513 14.2575L2.57022 16.1324C2.15226 16.316 1.9218 16.7692 2.02336 17.2106L2.96084 21.273C3.05849 21.6948 3.43348 21.9995 3.87488 21.9995C13.8786 21.9995 21.9995 13.8942 21.9995 3.87489C21.9995 3.4374 21.6987 3.05851 21.273 2.96085Z"
      fill="currentColor"
    />
  </svg>
  <svg
    v-else-if="appConfig.VueSettingsPreRun.IconsLayout === 'Poros'"
    class="v-icon-phone"
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.3456 3.86472L16.6894 3.02099C16.2922 2.92958 15.8844 3.137 15.7227 3.50965L14.0352 7.44706C13.8875 7.79159 13.986 8.19588 14.2778 8.43142L16.4082 10.1751C15.1426 12.8716 12.9313 15.1145 10.1786 16.4047L8.43492 14.2743C8.19587 13.9825 7.79509 13.884 7.45057 14.0317L3.51315 15.7192C3.13698 15.8844 2.92957 16.2922 3.02097 16.6894L3.8647 20.3456C3.95259 20.7253 4.29009 20.9995 4.68734 20.9995C13.6907 20.9995 20.9995 13.7047 20.9995 4.68736C20.9995 4.29361 20.7288 3.95261 20.3456 3.86472Z"
      stroke="currentColor"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
</script>
